import { KsChange } from '@knapsack/types';

type BroadcastChannelMessage = {
  type: 'data-change';
  dataChange: KsChange;
};

/**
 * A TypeSafe `BroadcastChannel` for sending and receiving data changes
 * This allows a user to have multiple browser windows open and see changes in real time
 * Faster than waiting for the DB Subscription to update the UI
 * @see https://developer.mozilla.org/en-US/docs/Web/API/BroadcastChannel
 */
export class DataChangesBroadcastChannel extends BroadcastChannel {
  override onmessage: (
    this: BroadcastChannel,
    ev: MessageEvent<BroadcastChannelMessage>,
  ) => void;

  constructor({ siteId, instanceId }: { siteId: string; instanceId: string }) {
    super(`knapsack-site-${siteId}-instance-${instanceId}-data-changes`);
  }

  override postMessage(message: BroadcastChannelMessage) {
    super.postMessage(message);
  }
}
