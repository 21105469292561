import { Machine, send, actions, sendParent } from 'xstate';
import { getXstateUtils, placeholderAction } from '@/core/xstate/xstate.utils';
import { assign } from '@xstate/immer';
import {
  Draft,
  applyPatches,
  enablePatches,
  produceWithPatches,
  castDraft,
  isDraft,
} from 'immer';
import { trackEvent } from '@/utils/analytics';
import { canRoleEdit, canRolePublish } from '@knapsack/core';
import { getDiff } from '@/utils/diff';
import { appUiVersion, isUnitTesting } from '@/utils/constants';
import { splitDataChangesByLastCommit } from '@/domains/branches/utils';
import {
  getAppClientData,
  submitDataForFileSave,
} from '@/services/app-client.client';
import {
  createBranchAndInstance,
  createCommitOnBranch,
  createPr,
  deleteDiscardedChanges,
  getBranchNameForInstance,
  mergePr,
  saveDataChanges,
  UpdateSiteInstanceStatus,
  branchInfo,
} from '@/domains/branches/api';
import { fixAppClientData } from '@knapsack/doctor';
import { KsChange } from '@/types';
import {
  debounce,
  now,
  stringHasSpaces,
  makeUuid as uuid,
  applyPatchesSafely,
} from '@knapsack/utils';
import { WS_EVENTS, WebSocketMessages } from '@knapsack/types';
import type { AppEvents } from '../app/app.xstate-utils';
import { SET_APP_CLIENT_DATA } from './reducers/shared.xstate';
import {
  APP_SUB_MACHINE_IDS,
  sendUserMessage,
  SharedEvents,
} from '../app/app.xstate-utils';
import { knapsackGlobal } from '../../../../global';
import {
  AppClientDataCtx,
  AppClientDataEvents,
  AppClientDataState,
  AppClientDataTypestates,
  initialCtx,
} from './types';
import { rootReducer, isTokenEvent, TokenEvents } from './reducers';
import { handleTokenUpdateEvents } from './tokens';
import { DataChangesBroadcastChannel } from './utils/data-changes-broadcast-channel';
import { mergeDeepInImmer } from './reducers/utils/utils.xstate';
import type { Site } from '../app/sub-states/site.xstate-types';

enablePatches();

const {
  createInvokablePromise,
  createXstateHooks: createAppClientDataXstateHooks,
} = getXstateUtils<
  AppClientDataCtx,
  AppClientDataEvents,
  AppClientDataTypestates,
  AppClientDataState
>();

export { createAppClientDataXstateHooks };

const loaderErrorMsg = `Error encountered with the branch you are trying to load might be corrupted or unmergable. Please contact support: help@knapsack.cloud`;

function possibleToSave(site: Site): boolean {
  if (
    site.env.type === 'development' &&
    site.contentSrc.type === 'current-env-server'
  ) {
    return true;
  }
  if (
    site.contentSrc.type === 'cloud-authoring' &&
    site.contentSrc.instance.type === 'branch'
  ) {
    return true;
  }
  return false;
}

function alterActiveCtx({
  ctx,
  recipe,
  event,
}: {
  ctx: AppClientDataCtx;
  event?: AppClientDataEvents;
  recipe: (active: Draft<AppClientDataCtx['active']>) => void;
}): AppClientDataCtx {
  const [nextState, patches, inversePatches] = produceWithPatches(
    ctx.active,
    recipe,
  );
  if (patches.length === 0) return ctx;
  // just telling TypeScript that this is not immutable
  const active = castDraft(nextState);
  const diff = getDiff<AppClientDataCtx['active']>({
    original: ctx.active,
    updated: active,
  });
  if (Object.keys(diff).length === 0) return ctx;
  const change: KsChange = {
    event,
    patches,
    inversePatches,
    id: uuid(),
    date: now(),
  };
  const initialVsActiveDiff = getDiff<AppClientDataCtx['active']>({
    original: ctx.initial,
    updated: active,
  });
  if (ctx.dataChangesBroadcastChannel) {
    ctx.dataChangesBroadcastChannel.postMessage({
      type: 'data-change',
      dataChange: change,
    });
  }
  return {
    ...ctx,
    active,
    initialVsActiveDiff,
    past: [...ctx.past, change],
    saveStack: [...ctx.saveStack, change],
  };
}

function updateTokensCtx({
  event,
  ctx,
}: {
  event: TokenEvents;
  ctx: AppClientDataCtx;
}): AppClientDataCtx {
  try {
    if (isDraft(ctx)) {
      throw new Error(`Do not call updateTokensCtx called with Immer draft`);
    }
    if (isDraft(ctx.active.tokensSrc)) {
      throw new Error(
        `Do not call updateTokensCtx called with Immer draft (ctx.active.tokensSrc)`,
      );
    }
    const { tokenChanges, tokenData, tokenStyles, tokensSrc } =
      handleTokenUpdateEvents({
        event,
        tokensSrc: ctx.active.tokensSrc,
      });
    return alterActiveCtx({
      event,
      ctx: {
        ...ctx,
        tokenData,
        tokenStyles,
      },
      recipe: (activeCtx) => {
        mergeDeepInImmer({
          target: activeCtx.tokensSrc,
          source: tokensSrc,
          sourceIsPartial: false,
        });
      },
    });
  } catch (error) {
    sendUserMessage({
      type: 'error',
      message: `Token error: ${error}`,
    });
  }
}

export const appClientDataMachine = Machine<
  AppClientDataCtx,
  AppClientDataState,
  AppClientDataEvents
>({
  id: APP_SUB_MACHINE_IDS.appClientData,
  context: initialCtx,
  initial: 'empty',
  on: {
    'userAndSite.haveBoth': {
      actions: [
        assign((ctx, { userId, roleForSite }) => {
          ctx.user = { userId, roleForSite };
        }),
        sendParent((ctx, event): SharedEvents => event),
      ],
    },
    'user.unload': {
      actions: assign((ctx) => {
        ctx.user = null;
      }),
    },
    'appClientData.setStatusMsg': {
      actions: assign((ctx, { status }) => {
        ctx.branchesStatusMsg = status;
      }),
    },
    'appClientData.clearStatusMsg': {
      actions: assign((ctx) => {
        ctx.branchesStatusMsg = null;
      }),
    },
  },
  states: {
    empty: {
      on: {
        [SET_APP_CLIENT_DATA]: {
          target: 'ready',
          actions: [
            assign((ctx, event) => {
              rootReducer(ctx.active, event);
              ctx.initial = ctx.active;
              ctx.initialVsActiveDiff = {};
              ctx.site = event.site;
            }),
            actions.assign((ctx, event) => {
              if (ctx.site.contentSrc.type !== 'cloud-authoring') return ctx;
              const { instanceDataChanges = [], latestDataChanges = [] } =
                event;
              if (isUnitTesting) return ctx;
              let initialData = ctx.initial;
              let activeData = ctx.active;
              function applyLatestDataChanges() {
                if (latestDataChanges.length === 0) return ctx.initial;
                try {
                  return applyPatchesSafely({
                    data: ctx.initial,
                    changes: latestDataChanges,
                  });
                } catch (error) {
                  console.error(
                    `Error applying latest data changes. Skipping those for now as those changes should be showing up after App Client finishes deploying.`,
                    error,
                  );
                  return ctx.initial;
                }
              }
              switch (ctx.site.contentSrc.instance.type) {
                case 'latest': {
                  initialData = applyLatestDataChanges();
                  activeData = initialData;
                  return {
                    ...ctx,
                    active: activeData,
                    initial: initialData,
                    initialVsActiveDiff: {},
                  };
                }
                case 'branch': {
                  try {
                    // first apply any patches that are needed on `latest`
                    // before we apply patches for this non-`latest` instance
                    initialData = applyLatestDataChanges();
                    activeData = initialData;
                    const { lastCommittedDataChangeId } =
                      ctx.site.contentSrc.instance;
                    if (lastCommittedDataChangeId) {
                      const { preCommitChanges, postCommitChanges } =
                        splitDataChangesByLastCommit({
                          changes: instanceDataChanges,
                          lastCommittedDataChangeId,
                        });
                      initialData = applyPatchesSafely({
                        data: initialData,
                        changes: preCommitChanges,
                      });
                      activeData = applyPatchesSafely({
                        data: initialData,
                        changes: postCommitChanges,
                      });
                    } else {
                      activeData =
                        instanceDataChanges.length === 0
                          ? initialData
                          : applyPatchesSafely({
                              data: initialData,
                              changes: instanceDataChanges,
                            });
                    }
                    const initialVsActiveDiff = getDiff({
                      original: initialData,
                      updated: activeData,
                    });
                    return {
                      ...ctx,
                      active: activeData,
                      initial: initialData,
                      initialVsActiveDiff,
                    };
                  } catch (error) {
                    console.error(error);
                    return {
                      ...ctx,
                      loaderError: loaderErrorMsg,
                    };
                  }
                }
                default: {
                  const _ex: never = ctx.site.contentSrc.instance;
                  return ctx;
                }
              }
            }),
            assign((ctx) => {
              fixAppClientData({
                appClientData: ctx.active,
              });
            }),
            actions.assign((ctx) => {
              return updateTokensCtx({
                ctx,
                event: {
                  type: 'tokens.setAll',
                  tokensSrc: ctx.active.tokensSrc,
                },
              });
            }),
            // this was `sendParent` but then I had problems with Unit Tests (and
            // I can't use `isUnitTesting` to conditionally call it) so this works
            // the same way as the parent machine is what send in the event this
            // action is `on`.
            actions.respond(
              (ctx): SharedEvents => ({
                type: 'appClientData.changed',
                appClientData: ctx.active,
              }),
            ),
            function saveFixedDataToLocal({
              site: { env, contentSrc },
              active,
            }) {
              if (
                // running local server
                env.type === 'development' &&
                // also getting our content from it
                contentSrc.type === 'current-env-server' &&
                // and we are in start mode
                env.appClientMeta.mode === 'start'
              ) {
                // in case the migrations changed any data, we want to immediately save it
                // otherwise we'd need to wait for the user to make a change
                // also this is async, but we don't need to `await` it
                submitDataForFileSave(active);
              }
            },
          ],
        },
      },
    },
    ready: {
      type: 'parallel',
      on: {
        'appClientData.prepForInstanceSwitch': {
          target: 'empty',
        },
      },
      entry: [
        assign((ctx) => {
          if (
            ctx.site.contentSrc.type === 'cloud-authoring' &&
            ctx.site.contentSrc.instance.type === 'branch'
          ) {
            const { instanceId } = ctx.site.contentSrc.instance;
            ctx.dataChangesBroadcastChannel = new DataChangesBroadcastChannel({
              siteId: ctx.site.meta.siteId,
              instanceId,
            });
          }
        }),
      ],
      exit: [
        assign((ctx) => {
          if (ctx.dataChangesBroadcastChannel) {
            ctx.dataChangesBroadcastChannel.close();
            ctx.dataChangesBroadcastChannel = undefined;
          }
        }),
      ],
      invoke: [
        {
          src: (ctx) => (sendEvent) => {
            const channel = ctx.dataChangesBroadcastChannel;
            if (!channel) return;
            channel.onmessage = (ev) => {
              const { data } = ev;
              switch (data.type) {
                case 'data-change': {
                  const { dataChange } = data;
                  sendEvent({
                    type: 'appClientData.externallyChanged',
                    subtype: 'changes',
                    changes: [dataChange],
                  });
                  break;
                }
                default: {
                  const _check: never = data.type;
                }
              }
            };
          },
        },
        {
          id: 'activeDataWatcher',
          // This is the best way to watch for context changes and be able to send
          // events into this machine or into the parent machine
          // (`knapsackGlobal.appService`) that I could find. Other solutions all
          // had different limitations. This only works because this machine (like
          // most others) will only have 1 instance (service) running ever
          src:
            ({ past }) =>
            (sendEvent) => {
              if (isUnitTesting) return;
              // this is the running version of this machine
              const me = knapsackGlobal.appClientDataService;
              const delay = 750;

              // let lastActive: AppClientDataCtx['active'] = initialActiveData;
              let lastPastLength = past.length;
              let timeoutId: NodeJS.Timeout;

              const { unsubscribe } = me.subscribe((state) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                  const ctx = state.context;
                  if (lastPastLength === ctx.past.length) {
                    // if the history has not changed, then we know that `active` has not changed either
                    return;
                  }
                  lastPastLength = ctx.past.length;
                  // const isSame = deepEqual(lastActive, ctx.active);
                  sendEvent({
                    type: 'appClientData.changed',
                    appClientData: ctx.active,
                  });
                  knapsackGlobal.appService.send({
                    type: 'appClientData.changed',
                    appClientData: ctx.active,
                  });
                }, delay);
              });

              return unsubscribe;
            },
        },
      ],
      states: {
        saver: {
          invoke: {
            src: (ctx) => (sendEvent) => {
              if (isUnitTesting) return;
              if (
                ctx.site.env.type !== 'development' ||
                ctx.site.contentSrc.type !== 'current-env-server'
              )
                return;
              const { websocketsEndpoint: appClientWebsocketsEndpoint } =
                ctx.site.env;
              if (!appClientWebsocketsEndpoint) return;
              const update = debounce(() => {
                try {
                  getAppClientData({
                    appClientUrl: ctx.site.env.url,
                  }).then(({ metaState, ...appClientData }) => {
                    sendEvent({
                      type: 'appClientData.externallyChanged',
                      subtype: 'fullData',
                      appClientData,
                    });
                  });
                } catch (e) {
                  sendEvent({
                    type: 'appClientData.saverError',
                    errorMsg: `Error with Local Dev WebSocket connection: ${e.message}`,
                  });
                }
              }, 1_500);
              const socket = new window.WebSocket(appClientWebsocketsEndpoint);

              socket.addEventListener('message', ({ data }) => {
                const theMsg: WebSocketMessages = JSON.parse(data ?? '{}');
                console.debug(
                  `app client websocket message: ${theMsg?.event}`,
                  theMsg,
                );
                switch (theMsg.event) {
                  case WS_EVENTS.RENDERER_CLIENT_RELOAD:
                  case WS_EVENTS.DESIGN_TOKENS_CHANGED:
                  case WS_EVENTS.APP_CLIENT_DATA_CHANGED: {
                    update();
                    break;
                  }
                }
              });

              socket.addEventListener('error', (error) => {
                console.error(error);
                sendEvent({
                  type: 'appClientData.saverError',
                  errorMsg: 'Error with Local Dev WebSocket connection',
                });
              });

              socket.addEventListener('close', (ev) => {
                sendEvent({
                  type: 'appClientData.saverError',
                  errorMsg: 'Local Dev WebSocket connection closed',
                });
              });

              return () => socket.close(1000, 'unmounting');
            },
          },
          initial: 'idle',
          on: {
            'appClientData.saverError': {
              target: '.error',
              actions: assign((ctx, event) => {
                ctx.saverError = event.errorMsg;
              }),
            },
            'appClientData.externallyChanged': {
              actions: [
                assign((ctx, event) => {
                  switch (event.subtype) {
                    case 'fullData': {
                      const { appClientData } = event;
                      const setEvent: AppClientDataEvents = {
                        type: SET_APP_CLIENT_DATA,
                        payload: appClientData,
                      };
                      return {
                        ...alterActiveCtx({
                          ctx,
                          event: setEvent,
                          recipe: (activeCtx) => {
                            return appClientData;
                          },
                        }),
                        saveStack: [],
                      };
                    }
                    case 'changes':
                      try {
                        const { changes } = event;
                        ctx.active = applyPatches(
                          ctx.active,
                          changes.flatMap((change) => {
                            if (ctx.past.find(({ id }) => change.id === id)) {
                              // if we already have this change in our past don't do it again
                              // can happen with pushing a change and then hearing about via websockets
                              return [];
                            }
                            return change.patches;
                          }),
                        );
                      } catch (error) {
                        console.error(error);
                        ctx.loaderError = loaderErrorMsg;
                        break;
                      }
                      break;
                    default: {
                      throw new Error(`Cannot handle`);
                    }
                  }
                }),
                // doing `assign` then `assign` in an `actions` causes `ctx` to be undefined FOR SOME REASON
                // turns out doing `assign` then `actions.assign` is the way to go
                actions.assign((ctx) => {
                  return updateTokensCtx({
                    ctx,
                    event: {
                      type: 'tokens.setAll',
                      tokensSrc: ctx.active.tokensSrc,
                    },
                  });
                }),
              ],
            },
          },
          states: {
            idle: {
              on: {
                'appClientData.saver.save': {
                  target: 'pushing',
                  cond: function isOkToSave(ctx) {
                    if (isUnitTesting) return false;
                    return possibleToSave(ctx.site);
                  },
                },
              },
              invoke: {
                id: 'saveStackWatcher',
                src: (ctx) => (sendEvent) => {
                  // heads up: `ctx` is only current when this state is entered - which is when `invoke.src` is ran
                  if (!possibleToSave(ctx.site)) {
                    return;
                  }
                  // this is the running instance of this machine
                  // This is the best way to watch for context changes and be able to send
                  // events into this machine that I could find. Other solutions all
                  // had different limitations. This only works because this machine (like
                  // most others) will only have 1 instance (service) running ever - i.e. singleton
                  const me = knapsackGlobal.appClientDataService;
                  const delay = 1_000;
                  let lastSaveStackLength = ctx.saveStack.length;
                  let timeoutId: NodeJS.Timeout;

                  const { unsubscribe } = me.subscribe((state) => {
                    const role = state.context.user?.roleForSite;
                    if (!role || !canRoleEdit(role)) {
                      // if they can't edit, then they can't save
                      return;
                    }
                    const saveStackTotal = state.context.saveStack.length;
                    if (saveStackTotal === 0) {
                      // nothing to save
                      return;
                    }
                    // not all changes to `state` need to be saved, so we check
                    // if the `saveStack` has grown since the last time we checked
                    if (saveStackTotal > lastSaveStackLength) {
                      lastSaveStackLength = saveStackTotal;
                      clearTimeout(timeoutId);
                      timeoutId = setTimeout(() => {
                        sendEvent('appClientData.saver.save');
                      }, delay);
                    }
                  });

                  return () => {
                    unsubscribe();
                  };
                },
              },
            },
            error: {
              exit: assign((ctx) => {
                ctx.saverError = null;
              }),
              on: {
                'appClientData.saverRetry': 'idle',
              },
            },
            pushing: {
              invoke: createInvokablePromise<{ changeIdsSaved: string[] }>({
                id: 'pusher',
                src: async (ctx) => {
                  const { saveStack, site } = ctx;
                  if (saveStack.length === 0) {
                    return { changeIdsSaved: [] };
                  }
                  const changeIdsSaved = saveStack.map(({ id }) => id);
                  if (
                    site.env.type === 'development' &&
                    site.contentSrc.type === 'current-env-server'
                  ) {
                    await submitDataForFileSave(ctx.active);
                    return { changeIdsSaved };
                  }
                  if (
                    site.contentSrc.type === 'cloud-authoring' &&
                    site.contentSrc.instance.type === 'branch'
                  ) {
                    await saveDataChanges({
                      changes: saveStack,
                      instanceId: site.contentSrc.instance.instanceId,
                      userId: ctx.user.userId,
                    });
                    return { changeIdsSaved };
                  }
                },
                onDone: {
                  target: 'idle',
                  actions: assign((ctx, { data: { changeIdsSaved } }) => {
                    // remove every change in the save stack we just saved - some changes may come in mid-save
                    ctx.saveStack = ctx.saveStack.filter(
                      ({ id }) => !changeIdsSaved.includes(id),
                    );
                  }),
                },
                onErrorTarget: 'error',
                onErrorActions: [
                  assign((ctx, event) => {
                    ctx.saverError = event.data.message;
                  }),
                  function userMessage(ctx, { data: error }) {
                    console.error(error);
                    if (isUnitTesting) return;
                    sendUserMessage({
                      type: 'error',
                      title: 'Error saving data',
                      message: error.message,
                    });
                  },
                ],
              }),
            },
          },
        },
        branches: {
          initial: 'unknown',
          states: {
            unknown: {
              always: [
                {
                  cond: function isOnLatest({ site }) {
                    return (
                      site.contentSrc.type === 'cloud-authoring' &&
                      site.contentSrc.instance.type === 'latest'
                    );
                  },
                  target: 'latest',
                },
                {
                  cond: function isOnBranch({ site }) {
                    return (
                      site.contentSrc.type === 'cloud-authoring' &&
                      site.contentSrc.instance.type === 'branch'
                    );
                  },
                  target: 'branch',
                },
              ],
            },
            latest: {
              initial: 'idle',
              states: {
                idle: {
                  on: {
                    'appClientData.createBranch': {
                      target: 'creatingBranch',
                      cond: function canUserEdit(ctx, event, meta) {
                        return canRoleEdit(ctx.user?.roleForSite);
                      },
                    },
                  },
                },
                error: {
                  exit: assign((ctx) => {
                    ctx.branchesStatusMsg = null;
                  }),
                  on: {
                    'appClientData.branchErrorReset': 'idle',
                  },
                },
                creatingBranch: {
                  invoke: createInvokablePromise<{
                    newInstanceId: string;
                    branchName: string;
                  }>({
                    id: 'create-branch-and-instance',
                    src: async (ctx, event) => {
                      if (event.type !== 'appClientData.createBranch') {
                        throw new Error(
                          `Cannot handle event type "${event.type}"`,
                        );
                      }
                      const { branchName } = event;
                      if (!branchName) {
                        throw new Error(`Branch name is required`);
                      }
                      if (stringHasSpaces(branchName)) {
                        throw new Error(
                          `Branch names cannot have spaces: "${branchName}"`,
                        );
                      }

                      const { branch, instanceId } =
                        await createBranchAndInstance({
                          siteId: ctx.site.meta.siteId,
                          branchName,
                          appClientDataMajorVersion: parseInt(
                            appUiVersion.split('.')[0],
                            10,
                          ),
                        });
                      if (branch !== branchName) {
                        throw new Error(
                          `Branch name requested "${branchName}" and created "${branch}" do not match`,
                        );
                      }
                      return {
                        newInstanceId: instanceId,
                        branchName,
                      };
                    },
                    onDoneTarget: 'idle',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      send(
                        (ctx, event): AppClientDataEvents => ({
                          type: 'appClientData.createBranch.error',
                          errorMsg: event.data.message,
                        }),
                      ),
                      assign((ctx, event) => {
                        ctx.branchesStatusMsg = {
                          type: 'error',
                          msg: event.data.message,
                        };
                      }),
                    ],
                    onDoneActions: [
                      send(
                        (): AppClientDataEvents => ({
                          type: 'appClientData.createBranch.done',
                        }),
                      ),
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            message: 'New branch created',
                            type: 'success',
                          });
                        },
                      },
                      sendParent(
                        (ctx, event): AppEvents => ({
                          type: 'site.switchInstance',
                          instanceId: event.data.newInstanceId,
                        }),
                        {
                          delay: 1000,
                        },
                      ),
                      () => {
                        trackEvent({
                          type: 'Branch Added',
                        });
                      },
                    ],
                  }),
                },
              },
            },
            branch: {
              initial: 'clean',
              states: {
                clean: {
                  initial: 'needsFirstCommit',
                  always: [
                    {
                      target: 'hasChanges',
                      cond: function diffFromInitial(ctx) {
                        return Object.keys(ctx.initialVsActiveDiff).length > 0;
                      },
                    },
                  ],
                  states: {
                    needsFirstCommit: {
                      always: [
                        {
                          target: 'idle',
                          cond: function hasFirstCommit({ site }) {
                            return site.contentSrc.type === 'cloud-authoring' &&
                              site.contentSrc.instance.type === 'branch'
                              ? site.contentSrc.instance.instanceStatus ===
                                  'CLEAN_DRAFT'
                              : false;
                          },
                        },
                      ],
                    },
                    idle: {
                      id: 'publish-idle',
                      on: {
                        'appClientData.publishBranch': {
                          target: 'publishing',
                          cond: function canUserEdit(ctx, event, meta) {
                            return canRolePublish(ctx.user?.roleForSite);
                          },
                        },
                        'appClientData.createPr': {
                          target: 'creatingPr',
                          cond: function canUserEdit(ctx, event, meta) {
                            return canRoleEdit(ctx.user?.roleForSite);
                          },
                        },
                      },
                    },
                    creatingPr: {
                      invoke: createInvokablePromise<{
                        prNumber: number;
                        prUrl: string;
                      }>({
                        id: 'creating-pr',
                        src: async (ctx, event) => {
                          if (event.type !== 'appClientData.createPr') {
                            throw new Error(
                              `Cannot handle event type "${event.type}"`,
                            );
                          }
                          if (
                            !(
                              ctx.site.contentSrc.type === 'cloud-authoring' &&
                              ctx.site.contentSrc.instance.type === 'branch'
                            )
                          ) {
                            throw new Error(
                              `Can only do this when on a cloud authoring branch`,
                            );
                          }
                          const { instanceId } = ctx.site.contentSrc.instance;
                          const { title, body } = event;
                          const { prNumber, prUrl } = await createPr({
                            siteId: ctx.site.meta.siteId,
                            instanceId,
                            title,
                            body,
                          });
                          if (!prNumber) {
                            throw new Error(`Was not able to create PR`);
                          }

                          await UpdateSiteInstanceStatus({
                            instanceId,
                            statusId: 'PROPOSED',
                          });

                          return {
                            prNumber,
                            prUrl,
                          };
                        },
                        onDoneTarget: 'idle',
                        onErrorTarget: 'error',
                        onErrorActions: [
                          send(
                            (ctx, event): AppClientDataEvents => ({
                              type: 'appClientData.createPr.error',
                              errorMsg: event.data.message,
                            }),
                          ),
                          assign((ctx, event) => {
                            if (
                              event.data.message.includes('No commits between')
                            ) {
                              ctx.branchesStatusMsg = {
                                type: 'error',
                                msg: 'You must commit changes before you can publish a branch',
                              };
                            } else if (
                              event.data.message.includes(
                                'A pull request already exists',
                              )
                            ) {
                              let prLink = null;

                              if (
                                ctx?.site?.meta.repoUrl?.includes(
                                  'github.com',
                                ) &&
                                'gitBranch' in ctx.site
                              ) {
                                prLink = `${ctx?.site?.meta.repoUrl}/pulls?q=is%3Apr+is%3Aopen+head%3A${ctx.site?.gitBranch}+is%3Aunmerged`;
                              }
                              ctx.branchesStatusMsg = {
                                type: 'error',
                                msg: `A pull request already exists between your branch and latest. Check out the ${
                                  prLink ? `[PR in Github](${prLink})` : 'PR'
                                } to review and merge.`,
                              };
                            } else {
                              ctx.branchesStatusMsg = {
                                type: 'error',
                                msg: event.data.message,
                              };
                            }
                          }),
                        ],
                        onDoneActions: [
                          send(
                            (): AppClientDataEvents => ({
                              type: 'appClientData.createPr.done',
                            }),
                          ),
                          assign((ctx, event) => {
                            if (
                              ctx.site.contentSrc.type === 'cloud-authoring' &&
                              ctx.site.contentSrc.instance.type === 'branch'
                            ) {
                              ctx.site.contentSrc.instance.instanceStatus =
                                'PROPOSED';
                            }
                            ctx.branchesStatusMsg = {
                              type: 'success',
                              msg: `Successfully created [PR ${event.data.prNumber}](${event.data.prUrl}).`,
                            };
                          }),
                        ],
                      }),
                    },
                    publishing: {
                      invoke: createInvokablePromise<{
                        prNumber: number;
                        prUrl: string;
                      }>({
                        id: 'publish-changes',
                        src: async (ctx, event) => {
                          if (event.type !== 'appClientData.publishBranch') {
                            throw new Error(
                              `Cannot handle event type "${event.type}"`,
                            );
                          }
                          if (
                            !(
                              ctx.site.contentSrc.type === 'cloud-authoring' &&
                              ctx.site.contentSrc.instance.type === 'branch'
                            )
                          ) {
                            throw new Error(
                              `Can only do this when on a cloud authoring branch`,
                            );
                          }
                          const { instanceId } = ctx.site.contentSrc.instance;
                          const { gitProviderType } = ctx.site.meta;
                          const { title, body } = event;
                          let prNumber: number;
                          let prUrl: string;
                          const {
                            siteInstances_by_pk: { gitBranch },
                          } = await getBranchNameForInstance({
                            instanceId,
                          });
                          const {
                            branchInfo: { prs },
                          } = await branchInfo({
                            branch: gitBranch,
                            siteId: ctx.site.meta.siteId,
                          });
                          if (prs.length > 1) {
                            throw new Error(
                              `There are more than 1 PRs associated with this branch "${event.type}"`,
                            );
                          }
                          if (prs.length > 0) {
                            const pr = prs[0];
                            prNumber = pr.prNumber;
                            prUrl = pr.prUrl;
                            if (pr.hasConflicts) {
                              throw new Error(
                                `Your branch could not be published because of merge conflicts. If you don't have access to the git repo, reach out to an engineer on your team. [Resolve conflicts](${prUrl})`,
                              );
                            }
                            if (!pr.isMergeable) {
                              throw new Error(
                                `Was not able to merge [PR in Github](${prUrl})`,
                              );
                            }
                          } else {
                            const createPrResult = await createPr({
                              siteId: ctx.site.meta.siteId,
                              instanceId,
                              title,
                              body,
                            });
                            prNumber = createPrResult.prNumber;
                            prUrl = createPrResult.prUrl;
                            // Merging GitLab PRs too quickly causes them to close instead
                            if (gitProviderType === 'gitLab') {
                              const {
                                featureFlags: { gitLabMergeDelay = 1000 },
                              } =
                                knapsackGlobal.appService.getSnapshot().context;
                              await new Promise((resolve) =>
                                setTimeout(resolve, gitLabMergeDelay),
                              );
                            }
                          }
                          if (!prNumber) {
                            throw new Error(
                              `Was not able to create and merge PR`,
                            );
                          }
                          // mark as Publishing first b/c mergePr sends a webhook to API where it will set status to Deleted (but not if it's Publishing)
                          // doing before mergePr to avoid race condition
                          // if error is thrown, then we set status back to Draft
                          await UpdateSiteInstanceStatus({
                            instanceId,
                            statusId: 'PUBLISHING',
                          });
                          await mergePr({
                            siteId: ctx.site.meta.siteId,
                            prNumber,
                          });
                          return {
                            prNumber,
                            prUrl,
                          };
                        },
                        onDoneTarget: 'idle',
                        onErrorTarget: 'error',
                        onErrorActions: [
                          (ctx) => {
                            if (
                              !(
                                ctx.site.contentSrc.type ===
                                  'cloud-authoring' &&
                                ctx.site.contentSrc.instance.type === 'branch'
                              )
                            ) {
                              throw new Error(
                                `Incorrect content source type: ${ctx.site.contentSrc.type}`,
                              );
                            }
                            const { instanceId } = ctx.site.contentSrc.instance;
                            UpdateSiteInstanceStatus({
                              instanceId,
                              statusId: 'DRAFT',
                            });
                          },
                          send(
                            (ctx, event): AppClientDataEvents => ({
                              type: 'appClientData.publishBranch.error',
                              errorMsg: event.data.message,
                            }),
                          ),
                          assign((ctx, event) => {
                            let prLink = null;
                            if (
                              ctx.site.meta.gitProviderType === 'gitHub' &&
                              'gitBranch' in ctx.site
                            ) {
                              prLink = `${ctx?.site?.meta.repoUrl}/pulls?q=is%3Apr+is%3Aopen+head%3A${ctx.site?.gitBranch}+is%3Aunmerged`;
                            }

                            if (
                              event.data.message.includes('No commits between')
                            ) {
                              ctx.branchesStatusMsg = {
                                type: 'error',
                                msg: 'You must commit changes before you can publish a branch',
                              };
                            } else if (
                              event.data.message.includes(
                                'A pull request already exists',
                              )
                            ) {
                              ctx.branchesStatusMsg = {
                                type: 'error',
                                msg: `A pull request already exists between your branch and latest. Check out the ${
                                  prLink ? `[PR in Github](${prLink})` : 'PR'
                                } to review and merge.`,
                              };
                            } else if (
                              event.data.message.includes('not mergeable')
                            ) {
                              ctx.branchesStatusMsg = {
                                type: 'error',
                                msg: `There's a conflict between your branch and latest. Please resolve the conflicts in your ${
                                  prLink
                                    ? `[pull request](${prLink})`
                                    : 'pull request'
                                }.`,
                              };
                            } else {
                              ctx.branchesStatusMsg = {
                                type: 'error',
                                msg: event.data.message,
                              };
                            }
                          }),
                        ],
                        onDoneActions: [
                          send(
                            (): AppClientDataEvents => ({
                              type: 'appClientData.publishBranch.done',
                            }),
                          ),
                          sendParent(
                            (): AppEvents => ({
                              type: 'site.switchInstance',
                              instanceId: 'latest',
                            }),
                            { delay: 1000 },
                          ),
                          assign((ctx, event) => {
                            ctx.branchesStatusMsg = {
                              type: 'info',
                              msg: 'Your changes have been published',
                            };
                          }),
                          {
                            type: 'sendUserMessage',
                            exec(ctx, event) {
                              sendUserMessage({
                                message:
                                  'Successfully published, switching back to latest.',
                                type: 'success',
                              });
                            },
                          },
                          () => {
                            trackEvent({
                              type: 'Branch Published',
                            });
                          },
                        ],
                      }),
                    },
                    error: {
                      entry: [
                        {
                          type: 'sendUserMessage',
                          exec(
                            ctx: AppClientDataCtx,
                            event: AppClientDataEvents,
                          ) {
                            sendUserMessage({
                              type: ctx.branchesStatusMsg.type,
                              message: ctx.branchesStatusMsg.msg,
                              autoClose: false,
                            });
                          },
                        },
                      ],
                      exit: assign((ctx) => {
                        ctx.branchesStatusMsg = null;
                      }),
                      on: {
                        'appClientData.branchErrorReset': 'idle',
                      },
                    },
                  },
                },
                hasChanges: {
                  id: 'hasUncommitedChanges',
                  on: {
                    'appClientData.commitChanges': {
                      target: 'committing',
                      cond: function canUserEdit(ctx, event, meta) {
                        return canRoleEdit(ctx.user?.roleForSite);
                      },
                    },
                    'appClientData.discardAllChanges': {
                      target: 'discarding',
                      actions: actions.assign((ctx, event) => {
                        return {
                          ...ctx,
                          active: ctx.initial,
                          past: [],
                          initialVsActiveDiff: {},
                        };
                      }),
                    },
                  },
                  always: [
                    {
                      target: 'clean',
                      cond: function isSameAsInitial(ctx) {
                        return (
                          Object.keys(ctx.initialVsActiveDiff).length === 0
                        );
                      },
                    },
                  ],
                },
                committing: {
                  invoke: createInvokablePromise<{
                    lastCommittedDataChangeId: string;
                  }>({
                    id: 'create-commit',
                    src: async (ctx: AppClientDataCtx, event) => {
                      if (event.type !== 'appClientData.commitChanges') {
                        throw new Error(
                          `Cannot handle event type "${event.type}"`,
                        );
                      }
                      if (
                        !(
                          ctx.site.contentSrc.type === 'cloud-authoring' &&
                          ctx.site.contentSrc.instance.type === 'branch'
                        )
                      ) {
                        throw new Error(
                          `Can only do this when on a cloud authoring branch`,
                        );
                      }
                      const { instanceId } = ctx.site.contentSrc.instance;
                      const { commitMessage } = event;

                      const {
                        commitChangesOnInstance: { lastDataChangeId },
                      } = await createCommitOnBranch({
                        siteId: ctx.site.meta.siteId,
                        instanceId,
                        commitMsg: commitMessage,
                      });

                      return {
                        lastCommittedDataChangeId: lastDataChangeId,
                      };
                    },
                    onDoneTarget: '#publish-idle',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      send(
                        (ctx, event): AppClientDataEvents => ({
                          type: 'appClientData.commitChanges.error',
                          errorMsg: event.data.message,
                        }),
                      ),
                      assign((ctx, event) => {
                        ctx.branchesStatusMsg = {
                          type: 'error',
                          msg: event.data.message,
                        };
                      }),
                    ],
                    onDoneActions: [
                      send(
                        (): AppClientDataEvents => ({
                          type: 'appClientData.commitChanges.done',
                        }),
                      ),
                      // reassigning initial
                      assign((ctx, { data: { lastCommittedDataChangeId } }) => {
                        if (
                          !(
                            ctx.site.contentSrc.type === 'cloud-authoring' &&
                            ctx.site.contentSrc.instance.type === 'branch'
                          )
                        ) {
                          throw new Error(
                            `Can only do this when on a cloud authoring branch`,
                          );
                        }
                        const { instanceId, instanceStatus } =
                          ctx.site.contentSrc.instance;
                        ctx.site.contentSrc.instance.lastCommittedDataChangeId =
                          lastCommittedDataChangeId;
                        ctx.initial = ctx.active;
                        ctx.initialVsActiveDiff = getDiff({
                          original: ctx.initial,
                          updated: ctx.active,
                        });

                        // make sure the DB reflected the next (expected) instanceStatus,
                        // factoring in if a PR had already been made or not
                        UpdateSiteInstanceStatus({
                          instanceId,
                          statusId:
                            instanceStatus === 'PROPOSED'
                              ? 'PROPOSED'
                              : 'DRAFT',
                        });
                      }),
                      () => {
                        trackEvent({
                          type: 'Branch Committed',
                        });
                      },
                    ],
                  }),
                },
                discarding: {
                  invoke: createInvokablePromise<{
                    deletedDataChangeIds: string[];
                  }>({
                    id: 'discard',
                    src: async (ctx, event) => {
                      if (event.type !== 'appClientData.discardAllChanges') {
                        throw new Error(
                          `Cannot handle event type "${event.type}"`,
                        );
                      }
                      if (
                        !(
                          ctx.site.contentSrc.type === 'cloud-authoring' &&
                          ctx.site.contentSrc.instance.type === 'branch'
                        )
                      ) {
                        throw new Error(
                          `Can only do this when on a cloud authoring branch`,
                        );
                      }
                      const { instanceId } = ctx.site.contentSrc.instance;

                      const { affectedRows, deletedDataChangeIds } =
                        await deleteDiscardedChanges({
                          instanceId,
                        });

                      return {
                        deletedDataChangeIds,
                      };
                    },
                    onDoneTarget: 'clean',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      send(
                        (ctx, event): AppClientDataEvents => ({
                          type: 'appClientData.discardAllChanges.error',
                          errorMsg: event.data.message,
                        }),
                      ),
                      assign((ctx, event) => {
                        ctx.branchesStatusMsg = {
                          type: 'error',
                          msg: event.data.message,
                        };
                      }),
                    ],
                    onDoneActions: [
                      send(
                        (): AppClientDataEvents => ({
                          type: 'appClientData.discardAllChanges.done',
                        }),
                      ),
                      assign((ctx, event) => {
                        ctx.branchesStatusMsg = {
                          type: 'success',
                          msg: `Discarded Changes.`,
                        };
                      }),
                      actions.assign(
                        (ctx, { data: { deletedDataChangeIds } }) => {
                          return {
                            ...ctx,
                            active: ctx.initial,
                            past: [],
                            initialVsActiveDiff: {},
                          };
                        },
                      ),
                    ],
                  }),
                },
                error: {
                  exit: assign((ctx) => {
                    ctx.branchesStatusMsg = null;
                  }),
                  on: {
                    'appClientData.commitErrorReset': 'clean',
                  },
                },
              },
            },
          },
        },
        /**
         * Watch for AppClientData changes so we can notify the parent when the
         * data has changed. `status` runs in parallel so it's `*` event will
         * capture all changes. If it was not `parallel` then more specific event
         * transitions (i.e. `on.someEvent`) would prevent `*` from hearing about
         * `someEvent`.
         */
        status: {
          initial: 'current',
          states: {
            current: {
              always: [
                {
                  target: 'changed',
                  cond: function diffFromInitial(ctx) {
                    return Object.keys(ctx.initialVsActiveDiff).length > 0;
                  },
                },
              ],
            },
            changed: {
              always: [
                {
                  target: 'current',
                  cond: function isSameAsInitial(ctx) {
                    return Object.keys(ctx.initialVsActiveDiff).length === 0;
                  },
                },
              ],
              on: {
                'appClientData.discardAllChanges': {
                  target: 'current',
                  actions: actions.assign((ctx, event) => {
                    return {
                      ...ctx,
                      active: ctx.initial,
                      past: [],
                      initialVsActiveDiff: {},
                    };
                    // disabling below code which allowed an undo of this operation but we had issues with restoring work after a discard all changes was also in the `past` history ~ https://linear.app/knapsack/issue/KSP-1348/discarding-changes-while-in-preview-mode-breaks-auto-save
                    // const data = alterActiveCtx({
                    //   ctx,
                    //   event,
                    //   recipe: (activeCtx) => {
                    //     return ctx.initial;
                    //   },
                    // });

                    // return data;
                  }),
                },
              },
            },
          },
        },
        data: {
          on: {
            // with `@ts-expect-error` this sometimes is an error and sometimes not; either way it's fine
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TS may thinks `*` is not a valid event type, but Xstate allows it as a catch-all for any events that are not already handled
            '*': {
              cond: function isRightKindOfEvent(ctx, event) {
                // event types that start with these string will not cause any changes when sent into the `rootReducer` so let's save computation time and also allow other transitions in this state machine to hear those events b/c they won't even hear the event if it's heard here
                return !['appClientData', 'user'].some((type) =>
                  event.type.startsWith(type),
                );
              },
              actions: [
                actions.assign(
                  (
                    ctx,
                    event: Exclude<
                      AppClientDataEvents,
                      { type: `user${string}` | `appClientData${string}` }
                    >,
                  ) => {
                    if (isTokenEvent(event)) {
                      return updateTokensCtx({
                        event,
                        ctx,
                      });
                    }
                    if (event.type === 'dataDoctor.run') {
                      return alterActiveCtx({
                        ctx,
                        event,
                        recipe: (activeCtx) => {
                          fixAppClientData({ appClientData: activeCtx });
                        },
                      });
                    }
                    return alterActiveCtx({
                      ctx,
                      event,
                      recipe: (activeCtx) => {
                        rootReducer(activeCtx, event);
                        // any events that should have the Doctor run after
                        switch (event.type) {
                          case 'knapsack/patterns/DELETE_TEMPLATE':
                          case 'knapsack/DELETE_PATTERN':
                            fixAppClientData({ appClientData: activeCtx });
                        }
                      },
                    });
                  },
                ),
              ],
            },
            'appClientData.getData.ping': {
              actions: [
                actions.respond((ctx): SharedEvents => {
                  return {
                    type: 'appClientData.getData.pong',
                    appClientData: ctx.active,
                  };
                }),
              ],
            },
            'appClientData.getData.pong': {
              actions: [placeholderAction],
            },
          },
        },
      },
    },
  },
});
