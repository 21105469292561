import { createHasuraClient } from '@knapsack/hasura-gql-client';
import { getUserToken } from '@/utils/user-token';
import { captureException } from '@/utils/sentry';
import { isBrowser, isNode } from '@knapsack/utils';
import { knapsackGlobal } from '@/global';
import { ksHttpHeaders } from '@knapsack/core';
import { extractPossibleInstanceIdFromSite } from '@/core/env-and-content-src/utils';

export * from '@knapsack/hasura-gql-client';

const { GRAPHQL_ENDPOINT = 'https://db.knapsack.cloud/v1/graphql' } =
  process.env;

export const hasuraGql = createHasuraClient({
  gqlServerUrl: GRAPHQL_ENDPOINT,
  getHeaders: async () => {
    const headers: Record<string, string> = {
      'Hasura-Client-Name': 'app-ui--graphql-request',
    };
    const { site } = knapsackGlobal.appService.getSnapshot().context;
    headers[ksHttpHeaders.siteId] = site?.meta.siteId;
    headers[ksHttpHeaders.instanceId] = extractPossibleInstanceIdFromSite(site);
    if (isBrowser) {
      try {
        const token: string = await getUserToken();
        if (token) {
          headers.Authorization = `Bearer ${token}`;
        }
      } catch (e) {
        // Muffle error here. Error is logged within getUserToken()
      }
    }
    if (isNode) {
      const { HASURA_ADMIN_SECRET } = process.env;
      if (HASURA_ADMIN_SECRET) {
        headers['X-Hasura-Admin-Secret'] = HASURA_ADMIN_SECRET;
      }
    }
    return headers;
  },
  logError: (error) => captureException(error),
});
