import { type Draft } from 'immer';
import type {
  TokenInfo,
  TokenSrcGroup,
  KsAppClientDataNoMeta,
} from '@knapsack/types';
import type { TokenSrcUpdater } from '@knapsack/design-token-utils';
import {
  SET_APP_CLIENT_DATA,
  SetAppClientData,
  RESET_APP_CLIENT_DATA,
  ResetAppClientData,
} from './shared.xstate';

export type TokensState = KsAppClientDataNoMeta['tokensSrc'];

export const tokensInitialState: TokensState = {};

export type TokensActions =
  | SetAppClientData
  | ResetAppClientData
  | { type: 'tokensError.reset' }
  | ({
      type: 'tokens.update';
    } & TokenInfo)
  | ({
      type: 'tokens.renameToken';
    } & Parameters<TokenSrcUpdater['renameToken']>[0])
  | {
      type: 'tokens.delete';
      tokenId: string;
    }
  | ({
      type: 'tokens.add';
    } & TokenInfo)
  | ({
      type: 'tokens.groupAdd';
    } & Parameters<TokenSrcUpdater['createGroup']>[0])
  | ({
      type: 'tokens.groupRename';
    } & Parameters<TokenSrcUpdater['renameGroup']>[0])
  | ({
      type: 'tokens.groupDelete';
    } & Parameters<TokenSrcUpdater['deleteGroup']>[0])
  | ({
      type: 'tokens.groupUpdate';
    } & Parameters<TokenSrcUpdater['updateGroup']>[0])
  // Token Theming start
  | ({
      type: 'tokens.setCollectionsParentKey';
    } & Parameters<TokenSrcUpdater['setCollectionsParentKey']>[0])
  // Collections start
  | ({
      type: 'tokens.col.add';
    } & Parameters<TokenSrcUpdater['collection']['create']>[0])
  | ({
      type: 'tokens.col.rename';
    } & Parameters<TokenSrcUpdater['collection']['rename']>[0])
  | ({
      type: 'tokens.col.delete';
    } & Parameters<TokenSrcUpdater['collection']['delete']>[0])
  // Collections end
  // Collection Modes start
  | ({
      type: 'tokens.col.mode.add';
    } & Parameters<TokenSrcUpdater['mode']['create']>[0])
  | ({
      type: 'tokens.col.mode.rename';
    } & Parameters<TokenSrcUpdater['mode']['rename']>[0])
  | ({
      type: 'tokens.col.mode.delete';
    } & Parameters<TokenSrcUpdater['mode']['delete']>[0])
  | ({
      type: 'tokens.col.mode.changeOrder';
    } & Parameters<TokenSrcUpdater['mode']['reorder']>[0])
  // Collection Modes end
  // Groups start
  | ({
      type: 'tokens.col.group.add';
    } & Parameters<TokenSrcUpdater['collectionGroup']['create']>[0])
  | ({
      type: 'tokens.col.group.rename';
    } & Parameters<TokenSrcUpdater['collectionGroup']['rename']>[0])
  | ({
      type: 'tokens.col.group.delete';
    } & Parameters<TokenSrcUpdater['collectionGroup']['delete']>[0])
  | ({
      type: 'tokens.col.group.move';
    } & Parameters<TokenSrcUpdater['collectionGroup']['move']>[0])
  | ({
      type: 'tokens.col.group.convert';
    } & Parameters<TokenSrcUpdater['collectionGroup']['convert']>[0])
  // Groups end
  // Vars start
  | ({
      type: 'tokens.col.var.add';
    } & Parameters<TokenSrcUpdater['var']['create']>[0])
  | ({
      type: 'tokens.col.var.rename';
    } & Parameters<TokenSrcUpdater['var']['rename']>[0])
  | ({
      type: 'tokens.col.var.delete';
    } & Parameters<TokenSrcUpdater['var']['delete']>[0])
  | ({
      type: 'tokens.col.var.move';
    } & Parameters<TokenSrcUpdater['var']['move']>[0])
  | ({
      type: 'tokens.col.var.setDescription';
    } & Parameters<TokenSrcUpdater['var']['setDescription']>[0])
  | ({
      type: 'tokens.col.var.setModeValue';
    } & Parameters<TokenSrcUpdater['var']['setModeValue']>[0])
  | ({
      type: 'tokens.col.var.unsetModeValue';
    } & Parameters<TokenSrcUpdater['var']['unsetModeValue']>[0])
  // Vars end
  // Token Theming end
  | {
      type: 'tokens.setAll';
      tokensSrc: TokenSrcGroup;
    };

/**
 * These are the events that should not be sent to the `rootReducer`,
 * but instead should be sent to web worker that uses `TokensSrcUpdater`
 */
export type TokenEvents = Extract<TokensActions, { type: `tokens.${string}` }>;

export default function reducer(
  data: Draft<KsAppClientDataNoMeta>,
  action: TokensActions,
): TokensState {
  switch (action.type) {
    case SET_APP_CLIENT_DATA:
      data.tokensSrc = action.payload.tokensSrc;
      return;
    case RESET_APP_CLIENT_DATA:
      data.tokensSrc = tokensInitialState;
  }
}
