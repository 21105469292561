import { KsChange } from '@/types';
import { isPatches } from '@knapsack/types';
import { hasuraGql as gql } from '@/services/hasura-client';
import { appApiGql } from '@/services/app-api-client';
import { now } from '@knapsack/utils';
import { splitDataChangesByLastCommit } from '../utils';

// GraphQL client for the app-api (Heroku)
export const {
  createCommitOnBranch,
  deleteRemoteBranchAndKsInstance,
  branchInfo,
} = appApiGql;
// Graphql client for the app-db (Hasura)
export const { UpdateSiteInstanceStatus, getBranchNameForInstance } = gql;

export async function deleteDiscardedChanges({
  instanceId,
}: {
  instanceId: string;
}): Promise<{ deletedDataChangeIds: string[]; affectedRows: number }> {
  const {
    siteInstance: { dataChanges, lastCommittedDataChangeId },
  } = await gql.GetDataChanges({
    instanceId,
  });
  const { postCommitChanges } = splitDataChangesByLastCommit({
    changes: dataChanges.map((change) => {
      return {
        ...change,
        patches: isPatches(change.patches) ? change.patches : [],
      };
    }),
    lastCommittedDataChangeId,
  });
  const deletedDataChangeIds =
    lastCommittedDataChangeId === null
      ? dataChanges.map(({ id }) => id)
      : postCommitChanges.map(({ id }) => id);
  const {
    delete_dataChanges: { affected_rows },
  } = await gql.DeleteDataChanges({
    dataChanges: deletedDataChangeIds,
  });
  return {
    deletedDataChangeIds,
    affectedRows: affected_rows,
  };
}

export async function saveDataChanges({
  changes,
  userId,
  instanceId,
}: {
  changes: KsChange[];
  userId: string;
  instanceId: string;
}): Promise<void> {
  const results = await gql.SaveDataChange({
    instanceId,
    now: now(),
    dataChange: {
      userId,
      instanceId,
      patches: changes.flatMap(({ patches }) => patches),
      inversePatches: changes.flatMap(({ inversePatches }) => inversePatches),
    },
  });
  const dataChangeId = results?.dataChange?.id;
  if (!dataChangeId) {
    throw new Error(`Could not save data changes`);
  }
}

export async function createBranchAndInstance({
  siteId,
  branchName,
  appClientDataMajorVersion,
}: {
  siteId: string;
  branchName: string;
  appClientDataMajorVersion: number;
}): Promise<{ branch: string; instanceId: string }> {
  const { createInstanceAndBranch } = await appApiGql.createBranch({
    siteId,
    branchName,
    appClientDataMajorVersion,
  });
  if (!createInstanceAndBranch) {
    throw new Error(`Could not create branch and instance`);
  }
  const { branch, instanceId } = createInstanceAndBranch;
  return {
    branch,
    instanceId,
  };
}

export async function createPr({
  siteId,
  instanceId,
  title,
  body,
}: {
  siteId: string;
  instanceId: string;
  title: string;
  body?: string;
}): Promise<{
  prNumber: number;
  prUrl: string;
}> {
  const { createPrFromInstance } = await appApiGql.createPullRequest({
    siteId,
    instanceId,
    title,
    body,
  });
  if (!createPrFromInstance?.prUrl) {
    throw new Error(
      `Could not create pull request from instance "${instanceId}"`,
    );
  }
  const { prNumber, prUrl } = createPrFromInstance;
  return {
    prNumber,
    prUrl,
  };
}

export async function mergePr({
  siteId,
  prNumber,
}: {
  siteId: string;
  prNumber: number;
}): Promise<void> {
  const { mergePR } = await appApiGql.mergePr({
    siteId,
    prNumber,
  });
  if (!mergePR) {
    throw new Error(`Could not merge pull request "${prNumber}"`);
  }
}
