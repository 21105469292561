import { compareDesc, parseISO } from 'date-fns';
import { KsRemoteChange } from '@/types';

export function swapInstanceIdInUrl({
  newInstanceId,
  absoluteUrl,
}: {
  newInstanceId: string;
  absoluteUrl?: boolean;
}): { oldInstanceId: string; newInstanceUrl: string } {
  const url = new URL(window.location.href);

  const paths = url.pathname.split('/').filter(Boolean);
  const [
    /** Most likely `site` */
    appBase,
    /** Which site we're in - i.e. `ks-demo-bootstrap` */
    siteId,
    /** Which site instance - i.e. `latest` or `R3vWHdP` */
    instanceId,
    /**
     * Base path for what type; `patterns`
     */
    basePath,
    /**
     * If base path is patterns, this would be patternId
     */
    firstParam,
    /**
     * If base path is patterns, this would be templateId
     */
    secondParam,
    ...restParams
  ] = paths;
  if (appBase !== 'site') {
    throw new Error(
      `Cannot swap instance ID in url unless pathname starts with "/site" - tried "${url.pathname}"`,
    );
  }
  const oldInstanceId = paths[2];
  paths[2] = newInstanceId; //

  return {
    oldInstanceId,
    newInstanceUrl: absoluteUrl
      ? new URL(`${paths.join('/')}${url.search}`, url.origin).toString()
      : `/${paths.join('/')}${url.search}`,
  };
}

/** Sort Site Instances (branches) with the most recently updated first */
export function sortSiteInstances<
  Inst extends {
    dateUpdated?: string;
  },
>(a: Inst, b: Inst): number {
  return compareDesc(parseISO(a.dateUpdated), parseISO(b?.dateUpdated));
}

export function splitDataChangesByLastCommit({
  changes,
  lastCommittedDataChangeId,
}: {
  changes: KsRemoteChange[];
  lastCommittedDataChangeId: string;
}): {
  preCommitChanges: KsRemoteChange[];
  postCommitChanges: KsRemoteChange[];
} {
  let found = false;
  return changes.reduce(
    (cur, change) => {
      if (found) {
        cur.postCommitChanges.push(change);
      } else {
        cur.preCommitChanges.push(change);
      }
      if (change.id === lastCommittedDataChangeId) {
        found = true;
      }
      return cur;
    },
    {
      preCommitChanges: [],
      postCommitChanges: [],
    } as {
      preCommitChanges: KsRemoteChange[];
      postCommitChanges: KsRemoteChange[];
    },
  );
}
