import { DeepPartial } from '@/utils/type-utils';
import { diff } from 'deep-object-diff';

export function getDiff<T extends Record<string, unknown>>({
  original,
  updated,
}: {
  original: T;
  updated: T;
}): DeepPartial<T> {
  // the return type is `object` which isn't that great
  return diff(original, updated) as DeepPartial<T>;
}
