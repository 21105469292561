import {
  Sender,
  Receiver,
  StateNodeConfig,
  TransitionConfig,
  send,
  forwardTo,
} from 'xstate';
import { assign } from '@xstate/immer';
import { getKsClaims, JwtWithCustomClaimsStruct } from '@knapsack/core';
import { gqlAppLoadClient } from '@/services/app-loading';
import { PromiseResult } from '@/utils/type-utils';
import { getUserToken } from '@/utils/user-token';
import { shallowEqual } from '@/utils/shallowEqual';
import { assertEventType } from '@/core/xstate/xstate.utils';
import {
  auth0,
  Auth0AppState,
  hasAuth0CallbackParams,
  isSsoCallbackHash,
  loginWithRedirect,
  logout,
} from '@/services/auth0';
import { isBrowser } from '@knapsack/utils';
import { uiMachine } from '../../ui/ui.xstate';

import {
  AppEvents,
  AppContext,
  AppStateSchema,
  createInvokablePromise,
  sendUserMessage,
} from '../app.xstate-utils';
import { knapsackGlobal } from '../../../../../global';
import { userLocalStorage } from '../../../../user.localstorage';
import { getUserRoleOverride } from '../../../../utils/user-override-utils';

/**
 * Fetch user from db
 */
async function loadUser({ userId, email }: { userId: string; email: string }) {
  const today = new Date().toISOString();
  if (!userId) throw new Error(`Load User missing userId`);
  if (!email) throw new Error(`Load User missing email`);
  try {
    const res = await gqlAppLoadClient.UpdateAndGetUser({
      email,
      userId,
      today,
    });
    return res.user;
  } catch (e) {
    console.error(e);
    throw new Error(
      `Loading User Details failed. userId probably does not exist in our DB: "${userId}", or auth token is bad.`,
    );
  }
}

// @TODO: Leaving this temporarily in case we need to bring it back.
// function removeParams() {
//   if (window.location.pathname === '/sign-up') {
//     return;
//   }

//   const params = new URLSearchParams(window.location.search);

//   params.delete('code');
//   params.delete('state');

//   const url = new URL(window.location.href);
//   url.search = params.toString();
//   window.history.replaceState({}, document.title, url.pathname);

//   console.debug(`Auth: Removed params from url`);
// }

const userAuthedTransition: TransitionConfig<
  AppContext,
  Extract<AppEvents, { type: 'user.authed' }>
> = {
  target: 'loggedIn',
  cond: function hasUseId(ctx, event) {
    if (event?.user?.userId) {
      return true;
    }
    console.error(
      `Attempting to transition to state "loggedIn" but the event does not contain userId`,
      { event },
    );
    return false;
  },
  actions: [
    assign((ctx, { user }) => {
      if (user.isSuperAdmin) {
        const roleOverride = getUserRoleOverride();
        if (roleOverride) {
          ctx.user = {
            ...user,
            getSiteRole: () => roleOverride,
          };
          return;
        }
      }
      ctx.user = user;
    }),
    forwardTo(uiMachine.id),
  ],
};

export const userStateConfig: StateNodeConfig<
  AppContext,
  AppStateSchema['states']['user'],
  AppEvents
> = {
  id: 'user',
  initial: 'unknown',
  strict: true,
  invoke: {
    id: 'auth0Observer',
    src: (ctx, event) => async (sendEvent: Sender<AppEvents>) => {
      if (!isBrowser) return;
      // window.auth0 = auth0;
      // console.log('window.auth0', auth0);
      const params = new URLSearchParams(window.location.search);
      const hasRedirectParams = hasAuth0CallbackParams(
        new URLSearchParams(window.location.search),
      );
      const isSsoCallback = isSsoCallbackHash();
      const error = params.get('error');
      const errorDescription = params.get('error_description');
      const hasErrorParams = !!error && !!errorDescription;
      if (hasErrorParams) {
        return sendEvent({
          type: 'user.authError',
          errorMsg: errorDescription,
        });
      }

      const isAuthenticated = await auth0.isAuthenticated();

      // What are those url query params still doing in url? get rid of them...
      // but continue execution. We should never be here.
      // if (isAuthenticated && hasRedirectParams) {
      //   removeParams();
      // }

      // We're not auth'd AND there are no URL params that look like either
      // 1. Username/password search params
      // 2. Hash params from auth0 SSO IdP callback
      if (!isAuthenticated && !hasRedirectParams && !isSsoCallback) {
        return sendEvent({
          type: 'user.unload',
        });
      }

      // @TODO: Move much of this logic to states, event, and transitions between
      // states. This is too much logic in the invoke function.

      // Even after signing in with auth0, we land on our app but *are not yet
      // fully logged in* until after a call back to auth0 either through
      // handleRedirectCallback() or loginWithRedirect()
      if (!isAuthenticated) {
        if (isSsoCallback) {
          await loginWithRedirect();
        } else if (hasRedirectParams) {
          try {
            const auth0AppState =
              await auth0.handleRedirectCallback<Auth0AppState>();
            const { preRedirectUrl } = auth0AppState.appState || {};
            if (preRedirectUrl) {
              if (knapsackGlobal?.goToUrl) {
                // Use Router if we have it
                knapsackGlobal?.goToUrl(preRedirectUrl);
              } else {
                // if knapsackGlobal.history (React Router) is not yet defined,
                // fall back to a manual redirect
                (window as Window).location = preRedirectUrl;
              }
            }
          } catch (e) {
            if (e instanceof Error)
              sendEvent({
                type: 'user.authError',
                errorMsg: `Auth: Failed to parse auth redirect params. ${e.message}`,
              });
            return;
          }
        } else {
          await loginWithRedirect();
        }
      }

      // Should have user by this point, either from cache or by parsing query params
      const authUser = await auth0.getUser();
      if (!authUser) {
        // Bail
        return sendEvent({
          type: 'user.authError',
          errorMsg: `Auth: Failed to get user from auth0.`,
        });
      }
      console.debug(`Auth: have user from Auth0`, authUser);

      try {
        const authToken: string = await getUserToken();
        console.debug(`Auth: auth token retrievable`, authToken);
      } catch (e) {
        // Bail
        if (e instanceof Error)
          return sendEvent({
            type: 'user.authError',
            errorMsg: `Auth: Failed to get token. ${e.message}`,
          });
      }

      const claims = await auth0.getIdTokenClaims();
      if (!JwtWithCustomClaimsStruct.is(claims)) {
        const errorMsg = `Auth: claims are not a JWT with custom claims (JwtWithCustomClaims)`;
        console.error(errorMsg, claims);
        sendEvent({
          type: 'user.authError',
          errorMsg,
        });
        return;
      }
      const { isSuperAdmin, siteRoleMap, userId, getSiteRole } =
        getKsClaims(claims);
      console.debug(`Auth: have claims`, claims);

      const {
        email,
        email_verified: emailVerified,

        // Reference for (some of) what's available on claims
        // sub, // auth0Id in db
        // picture, // profile image url
      } = authUser;

      console.debug(`Auth: success! sending in user.authed event...`);
      sendEvent({
        type: 'user.authed',
        user: {
          userId,
          email,
          emailVerified,
          isSuperAdmin,
          membershipSiteIds: Object.keys(siteRoleMap ?? {}).sort(),
          getSiteRole,
        },
      });

      return () => {};
    },
  },
  states: {
    unknown: {
      on: {
        'user.unload': 'loggedOut',
        'user.authError': {
          target: 'authError',
          actions: [
            {
              type: 'sendUserMessage',
              exec(ctx, event) {
                sendUserMessage({
                  type: 'error',
                  message: event.errorMsg,
                });
              },
            },
            assign((ctx, event) => {
              const [title, ...details] = event.errorMsg.split('\n');
              ctx.userError = {
                title,
                details: details.join('\n'),
              };
            }),
          ],
        },
        'user.authed': userAuthedTransition,
      },
    },
    authError: {
      exit: assign((ctx) => {
        ctx.userError = null;
      }),
      on: {
        'user.authed': userAuthedTransition,
        'user.clearError': {
          actions: () => logout(),
        },
        'user.signOut': {
          actions: () => logout(),
        },
      },
    },
    loggedOut: {
      on: {
        'user.authed': userAuthedTransition,
      },
    },
    loggedIn: {
      initial: 'loadingDetails',
      exit: [
        assign((ctx) => {
          ctx.user = null;
        }),
        {
          type: 'clearing Apollo cache',
          exec: () =>
            import('@/services/util-apollo-graphql.client').then(
              ({ resetApolloClientStore }) => resetApolloClientStore(),
            ),
        },
      ],
      on: {
        'user.unload': {
          target: 'loggedOut',
          actions: [forwardTo(uiMachine.id)],
        },
        'user.signOut': {
          actions: [
            {
              type: 'signOut',
              exec() {
                userLocalStorage.setItem({
                  redirectUrl: window.location.href.replace(
                    window.location.origin,
                    '',
                  ),
                });
                logout();
              },
            },
          ],
        },
      },
      states: {
        loadingDetails: {
          invoke: createInvokablePromise<PromiseResult<typeof loadUser>>({
            id: 'loadUserInfo',
            src: async (ctx, event) =>
              loadUser({
                userId: ctx.user?.userId,
                email: ctx.user?.email,
              }),
            onDoneTarget: 'loaded',
            onDoneAssignContext({ ctx, data }) {
              ctx.user.info = data;
            },
            onErrorTarget: 'loadingError',
            onErrorAssignContext({ ctx, error }) {
              ctx.userError = {
                title: error.message,
                details: error.stack,
              };
            },
            onErrorActions: [
              {
                type: 'sendUserMessage',
                exec(ctx, event) {
                  sendUserMessage({
                    type: 'error',
                    title: 'Error loading user info',
                    message: event.data.message,
                  });
                },
              },
            ],
          }),
        },
        loadingError: {
          exit: [
            assign((ctx) => {
              ctx.userError = undefined;
            }),
          ],
        },
        loaded: {
          type: 'parallel',
          states: {
            userInfo: {
              initial: 'idle',
              states: {
                idle: {
                  on: {
                    'user.updateInfo': {
                      target: 'updating',
                      // only update if the values are different
                      cond: function areValuesDifferent({ user }, event) {
                        return !shallowEqual(user.info, event.info);
                      },
                    },
                    'user.infoChanged': {
                      actions: assign((ctx, event) => {
                        ctx.user.info = event.info;
                      }),
                    },
                  },
                },
                updating: {
                  invoke: {
                    src:
                      ({ user }, event) =>
                      (
                        sendEvent: Sender<AppEvents>,
                        onEvent: Receiver<AppEvents>,
                      ) => {
                        assertEventType(event, 'user.updateInfo');
                        const { info, successMsg, email } = event;

                        const { dateCreated, ...restOriginalUser } = user.info;

                        // Merge incoming changes into original user values
                        const userInfo = {
                          ...restOriginalUser,
                          ...info,
                        };

                        const isDisplayNameDiff =
                          userInfo.displayName !== user.info?.displayName;
                        const hasEmailChanged = !!email;
                        // should these be lowercase? I'm thinking no...
                        const isEmailDiff =
                          hasEmailChanged && email !== user.email;

                        import('@/services/hasura-client')
                          .then(({ hasuraGql }) =>
                            hasuraGql.UpdateUserInfo({
                              userId: user.userId,
                              user: {
                                ...userInfo,
                                email: isEmailDiff ? email : user.email,
                              },
                            }),
                          )
                          .then((results) =>
                            sendEvent({
                              type: 'user.updateInfo.done',
                              info: userInfo,
                              successMsg,
                            }),
                          )
                          .catch((err) => {
                            console.error(err);
                            sendEvent({
                              type: 'user.updateInfo.fail',
                              msg: err.message,
                            });
                          });
                      },
                  },
                  on: {
                    'user.updateInfo.done': {
                      target: 'idle',
                      actions: [
                        assign((ctx, event) => {
                          ctx.user.info = event.info;
                        }),
                        send(
                          (ctx, event): AppEvents => ({
                            type: 'app.sendUserMsg',
                            msg: {
                              message: event.successMsg || 'Updated Profile',
                              type: 'success',
                            },
                          }),
                        ),
                      ],
                    },
                    'user.updateInfo.fail': {
                      target: 'idle',
                      actions: [
                        send(
                          (ctx, event): AppEvents => ({
                            type: 'app.sendUserMsg',
                            msg: {
                              message: event.msg,
                              type: 'error',
                            },
                          }),
                        ),
                      ],
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};
