const key = ({ siteId }: { siteId: string }) => `ks-env-url--${siteId}`;

export function setActiveEnvUrl({
  siteId,
  appClientUrl,
}: {
  siteId: string;
  appClientUrl: string;
}) {
  window.localStorage.setItem(key({ siteId }), appClientUrl);
}

export function getActiveEnvUrl({ siteId }: { siteId: string }): string | null {
  const url = window.localStorage.getItem(key({ siteId }));
  return url || null;
}

export function removeActiveEnvUrl({ siteId }: { siteId: string }) {
  window.localStorage.removeItem(key({ siteId }));
}
